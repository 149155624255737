import { auth, db } from '../config';
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { APPLICATION_TEMPLATES, COURSE, PROFILE } from '../constants';
import { fireLogEvent } from './analytics';
import {
  ANALYTICS_EVENTS,
  COURSE_EVENTS,
  TEMPLATE_EVENTS,
} from '../../constants/firebaseAnalytics';
import { getLocalUser } from '../../utils/localStorage';
import { advanceDateByYear } from '../../utils/helper';
import { documentExists } from './auth';

const user = getLocalUser();

// Edit Course
export const updateCourse = (course) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, COURSE, course?.id), {
      ...course,
      updatedBy: auth?.currentUser?.uid,
      updateCount: increment(1),
      updatedAt: serverTimestamp(),
    })
      .then(() => {
        resolve(true);
        fireLogEvent(COURSE_EVENTS?.COURSE, {
          type: COURSE_EVENTS?.COURSE_UPDATED,
        });
      })
      .catch((error) => reject(false));
  });

// Edit Story Item
export const updateStoryItem = (storyItemDetails, collectionName) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, collectionName, storyItemDetails?.id), {
      ...storyItemDetails,
      updatedBy: storyItemDetails?.createdBy
        ? storyItemDetails?.createdBy
        : auth?.currentUser?.uid,
      updateCount: increment(1),
      updatedAt: serverTimestamp(),
    })
      .then(() => {
        resolve(storyItemDetails?.id);
        fireLogEvent(ANALYTICS_EVENTS[collectionName].LABEL, {
          type: ANALYTICS_EVENTS[collectionName].UPDATED,
        });
      })
      .catch((error) => {
        reject(false);
      });
  });

export const editProfile = (profile) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, PROFILE, profile?.id), {
      ...profile,
      updates: increment(1),
    })
      .then(() => resolve(true))
      .catch((error) => reject(false));
  });

export const editRequirementTemplate = (data, id) =>
  new Promise((resolve, reject) => {
    updateDoc(doc(db, APPLICATION_TEMPLATES, id), {
      ...data,
      updatedAt: serverTimestamp(),
      updatedBy: auth?.currentUser?.uid ?? user?.uid,
      updateCount: increment(1),
    })
      .then(() => {
        resolve(id);
        fireLogEvent(TEMPLATE_EVENTS?.TEMPLATE, {
          type: TEMPLATE_EVENTS?.TEMPLATE_UPDATED,
        });
      })
      .catch((error) => {
        reject(false);
      });
  });

//Utility to update a document in a collection based on a search key and value
export const updateDocument = async (
  collectionName,
  searchKey,
  searchValue,
  key,
  payload,
  shouldReplace = false
) => {
  try {
    const q = query(
      collection(db, collectionName),
      where(searchKey, '==', searchValue)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log('No matching documents found.');
      return;
    }

    // Assuming only one document matches the query
    const docId = querySnapshot.docs[0].id;

    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.log('No such document!');
      return;
    }

    const currentData = docSnap.data();
    const currentFieldValue = currentData[key];

    let newValue;
    if (shouldReplace || currentFieldValue === undefined) {
      // Replace the value completely
      newValue = payload;
    } else if (Array.isArray(currentFieldValue) && Array.isArray(payload)) {
      // Spread and add to an array
      newValue = [...currentFieldValue, ...payload];
    } else if (
      typeof currentFieldValue === 'object' &&
      typeof payload === 'object'
    ) {
      // Spread and add to an object
      newValue = { ...currentFieldValue, ...payload };
    } else {
      // Replace if types don't match or spreading is not applicable
      newValue = payload;
    }

    await updateDoc(docRef, {
      [key]: newValue,
    });
    console.log('Document successfully updated!');
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

const getDocumentByKeyValue = async (collectionName, key, value) => {
  console.log(`Searching for document with ${key} = ${value}`);
  const collectionRef = collection(db, collectionName);
  const q = query(collectionRef, where(key, '==', value));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null; // No matching document found
  }

  // Assuming there's only one document that matches the query
  const docData = querySnapshot.docs[0].data();
  const docId = querySnapshot.docs[0].id;
  console.log({ docId, docData });
  return { docId, docData };
};

const updateDocumentById = async (collectionName, docId, updateData) => {
  const docRef = doc(db, collectionName, docId);
  await updateDoc(docRef, updateData);
  console.log(`Document with ID ${docId} updated successfully.`);
};

// Utility to get document data from a collection based on a search key and value and update it
export const updateTeamMemberUIDByEmail = async (email, newUid) => {
  console.log(`Updating UID for team member with email ${email}`);
  const collectionName = 'teams';
  const key = 'studentEmail'; // Assuming this is a valid path for where clause

  const result = await getDocumentByKeyValue(collectionName, key, email);
  console.log(result);
  if (!result) {
    console.log(`No team member found with email ${email}`);
    return;
  }

  const { docId, docData } = result;

  // Update the team member's UID
  const updatedTeamMembers = docData.teamMembers.map((member) => {
    if (member.email === email) {
      return {
        ...member,
        uid: newUid,
      };
    }
    return member;
  });

  await updateDocumentById(collectionName, docId, {
    teamMembers: updatedTeamMembers,
  });
  console.log(`UID updated for team member with email ${email}`);
};

//  Utility to update all documents in a collection with a new value
export const updateAllDocuments = async (collectionName, updatedValue) => {
  const success = [];
  const failed = [];

  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    for (const documentSnapshot of querySnapshot.docs) {
      const docRef = doc(db, collectionName, documentSnapshot.id);

      try {
        await updateDoc(docRef, updatedValue);
        success.push({ id: documentSnapshot.id });
      } catch (error) {
        failed.push({ id: documentSnapshot.id });
        console.error('Error updating document: ', documentSnapshot.id, error);
      }
    }
  } catch (error) {
    console.error('Error getting documents: ', error);
  }

  return { success, failed };
};

export const advanceDateToYearInDocuments = async (collectionName) => {
  const success = [];
  const failed = [];

  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    for (const documentSnapshot of querySnapshot.docs) {
      const docRef = doc(db, collectionName, documentSnapshot.id);
      const data = documentSnapshot.data();

      // Assuming the field containing the array of objects is named 'rounds'
      if (Array.isArray(data.rounds)) {
        const updatedArray = data.rounds.map((item) => {
          if (item.applicationDueDate) {
            item.applicationDueDate = advanceDateByYear(
              item.applicationDueDate.toDate()
            );
          }
          if (item.applicationOpenDate) {
            item.applicationOpenDate = advanceDateByYear(
              item.applicationOpenDate.toDate()
            );
          }
          if (item.decisionDueDate) {
            item.decisionDueDate = advanceDateByYear(
              item.decisionDueDate.toDate()
            );
          }
          if (item.financialAidDueDate) {
            item.financialAidDueDate = advanceDateByYear(
              item.financialAidDueDate.toDate()
            );
          }
          return item;
        });

        try {
          await updateDoc(docRef, { rounds: updatedArray });
          console.log('Updated Document', documentSnapshot.id);
          success.push({ id: documentSnapshot.id });
        } catch (error) {
          failed.push({ id: documentSnapshot.id });
          console.error(
            'Error updating document: ',
            documentSnapshot.id,
            error
          );
        }
      } else {
        failed.push({
          id: documentSnapshot.id,
          error: 'rounds field is not an array',
        });
      }
    }
  } catch (error) {
    console.error('Error getting documents: ', error);
  }

  return { success, failed };
};

export const deleteMember = async (teamMembers, teamId, requestedMember) => {
  const updatedMembersList = teamMembers?.filter(
    (member) => member?.uid !== requestedMember
  );

  await updateDocument(
    'teams',
    'id',
    teamId,
    'teamMembers',
    updatedMembersList,
    true
  );
  await updateDocument('profile', 'uid', requestedMember, 'teams', [], true);
};
export const leaveTeam = async (teamId, requestedMember) => {
  const team = await documentExists('teams', 'id', teamId);

  if (!team) {
    return;
  }
  const teamData = team.data[0];
  const updatedMembersList = teamData.teamMembers?.filter(
    (member) => member?.uid !== requestedMember
  );

  await updateDocument(
    'teams',
    'id',
    teamId,
    'teamMembers',
    updatedMembersList,
    true
  );

  const profile = await documentExists('profile', 'uid', requestedMember);

  if (!profile) {
    return;
  }

  const profileData = profile.data[0];
  const updatedTeamsList = profileData.teams?.filter(
    (team) => team?.teamId !== teamId
  );

  await updateDocument(
    'profile',
    'uid',
    requestedMember,
    'teams',
    updatedTeamsList,
    true
  );
  return true;
};

export const assignAllTeamsForProfile = async (uid) => {
  // 1. first get profile data from uid
  // 2. Get all teams data
  // 3. create and array of objects with teamId and status: status: 'JOINED'
  // 4. update profile with teams array with field teams

  // 1. first get profile data from uid
  const profileCollectionRef = collection(db, 'profile');
  const profileQuery = query(profileCollectionRef, where('uid', '==', uid));
  const profileSnapshot = await getDocs(profileQuery);

  if (profileSnapshot.empty) {
    console.log('No such profile document!');
    return null;
  }

  // 2. Get all teams data
  const teamsCollectionRef = collection(db, 'teams');
  const teamsData = await getDocs(teamsCollectionRef);

  // 3. create and array of objects with teamId and status: status: 'JOINED'
  const teamsArray = [];
  teamsData.forEach((doc) => {
    teamsArray.push({
      teamId: doc.id,
      status: 'JOINED',
    });
  });

  const profileDocRef = profileSnapshot.docs[0].ref;

  // 4. update profile with teams array with field teams
  for (const team of teamsArray) {
    await updateDoc(profileDocRef, {
      teams: arrayUnion(team),
    });
  }
};

// Parameters may be declared in a variety of syntactic forms
/**
 * A function that adds a parent to team utilities like niche, honor, activities, courses, essay
 * @param {string}  teamId - A string param Uid.
 * @param {string}  parentUid - A string param.
 */
export const addParentToTeamUtilities = async (teamId, parentUid) => {
  try {
    // 1. Get team data from where id = teamId without fetching all data
    const teamDocRef = doc(db, 'teams', teamId);
    const teamDoc = await getDoc(teamDocRef);
    const teamData = teamDoc.data();

    // 2. declare a variable to store studentEmail from team data
    const studentEmail = teamData.studentEmail;

    // 3. Get all utilities separately like (niche, honor, activities, courses, essay) where owner is studentEmail
    // 3.1 Get niche data
    const nicheCollectionRef = collection(db, 'niche');
    const allNiches = query(
      nicheCollectionRef,
      where('owner', '==', studentEmail),
      where('deleteDocument', '==', false)
    );
    const nicheData = await getDocs(allNiches);
    nicheData.forEach(async (doc) => {
      const nicheDocRef = doc.ref;
      await updateDoc(nicheDocRef, {
        sharedWith: arrayUnion(parentUid),
      });
    });

    // 3.2 Get honor data
    const honorCollectionRef = collection(db, 'honor');
    const allHonors = query(
      honorCollectionRef,
      where('owner', '==', studentEmail),
      where('deleteDocument', '==', false)
    );
    const honorData = await getDocs(allHonors);
    honorData.forEach(async (doc) => {
      const honorDocRef = doc.ref;
      await updateDoc(honorDocRef, {
        sharedWith: arrayUnion(parentUid),
      });
    });

    // 3.3 Get activities data
    const activitiesCollectionRef = collection(db, 'activity');
    const allActivities = query(
      activitiesCollectionRef,
      where('owner', '==', studentEmail),
      where('deleteDocument', '==', false)
    );
    const activitiesData = await getDocs(allActivities);
    activitiesData.forEach(async (doc) => {
      const activitiesDocRef = doc.ref;
      await updateDoc(activitiesDocRef, {
        sharedWith: arrayUnion(parentUid),
      });
    });

    // 3.4 Get courses data
    const coursesCollectionRef = collection(db, 'course');
    const allCourses = query(
      coursesCollectionRef,
      where('owner', '==', studentEmail),
      where('deleteDocument', '==', false)
    );
    const coursesData = await getDocs(allCourses);
    coursesData.forEach(async (doc) => {
      const coursesDocRef = doc.ref;
      await updateDoc(coursesDocRef, {
        sharedWith: arrayUnion(parentUid),
      });
    });

    // 3.5 Get essay data
    const essayCollectionRef = collection(db, 'essay');
    const allEssays = query(
      essayCollectionRef,
      where('owner', '==', studentEmail),
      where('deleteDocument', '==', false)
    );
    const essayData = await getDocs(allEssays);
    essayData.forEach(async (doc) => {
      const essayDocRef = doc.ref;
      await updateDoc(essayDocRef, {
        sharedWith: arrayUnion(parentUid),
      });

      // NOTE: We are not adding parent to google docs for now since parent has different Auth token and we can't add add parent to student's google docs
    });

     // 3.6 Get all applications data
    const applicationsCollectionRef = collection(db, 'applications');
    const allApplications = query(
      applicationsCollectionRef,
      where('owner', '==', studentEmail), 
      where('deleteDocument', '==', false));
    const applicationsData = await getDocs(allApplications);
    applicationsData.forEach(async (doc) => {
      const applicationsDocRef = doc.ref;
      await updateDoc(applicationsDocRef, {
        sharedWith: arrayUnion(parentUid),
      });
    });

    return true;
  } catch (error) {
    console.error('Error updating document: ', error);
    return false;
  }
};
