import { CircularProgress, Drawer } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  addStoryItemTypes,
  collegeApplicationStatusList,
} from '../../constants/other';
import {
  CHAT_MESSAGES,
  CHAT_ROOMS,
  UNIVERSILY_TEMPLATES,
} from '../../firebase/constants';
import { addStoryItem } from '../../firebase/services/addServices';
import {
  getMessagesForChatRoom,
  getStoryItem,
} from '../../firebase/services/getServices';
import { updateStoryItem } from '../../firebase/services/updateServices';
import { getProfileData } from '../../firebase/services/user';
import useActivityStore from '../../stores/ActivityStore';
import useChatStore from '../../stores/ChatStore';
import useHonorStore from '../../stores/HonorStore';
import useInviteAFriendStore from '../../stores/InviteAFriendStore';
import useMyTeamStore from '../../stores/MyTeamStore';
import useNicheStore from '../../stores/NicheStore';
import useProfileStore from '../../stores/ProfileStore';
import useStudentStore from '../../stores/StudentStore';
import useThemeStore from '../../stores/ThemeStore';
import SuccessSnackbar from '../shared/SuccessSnackbar';
import ChatBody from './ChatBody';
import ChatList from './ChatList';
import { ApiRoutes } from '../../firebase/apis';
import { axiosPost } from '../../firebase/axios';
import { format } from 'date-fns';
import config from '../../utils/urlConfig';
import { ASK_UNI_MAIL } from '../../constants/staticData';
import { firebaseQueryOperators } from '../../firebase/queryBuilder';
import { useAuth, useGoogleDocs } from '../../hooks';
import {
  getActTestContext,
  getActivityContext,
  getCollegeName,
  getCourseContext,
  getEssayContext,
  getHonorContext,
  getNicheContext,
  getNicheStoryItemContext,
  getSatTestContext,
  getUserContext,
  sortArrayByRecentDate,
} from '../../utils/helper';
import useCourseStore from '../../stores/CourseStore';
import useEssayStore from '../../stores/EssayStore';
import useAdminStore from '../../stores/AdminStore';
import useTestStore from '../../stores/TestStore';
import useApplicationStore from '../../stores/ApplicationStore';
import { welcomeMessageFromAI } from '../../constants/helperText';
import { useGPAStore } from '../../stores/GPAStore';
import { serverTimestamp } from 'firebase/firestore';
import { useMyStoryStore } from '../../stores/myStoryStore';
import { useShallow } from 'zustand/react/shallow';
import { INVITE_STATUS } from '../../utils/utlityTypes';
import useTeamStore from '../../stores/TeamStore';

const PROMPTS_WITH_EXTRA_INFORMATION = {
  'AI-Prompt-Essay-Review': 'AI-Prompt-Essay-Review',
  'AI-Prompt-Essay-Outline': 'AI-Prompt-Essay-Outline',
  'AI-Prompt-Essay-Brainstorm': 'AI-Prompt-Essay-Brainstorm',
  'AI-Prompt-Application-ChanceMe': 'AI-Prompt-Application-ChanceMe',
};

const ChatWindow = ({
  height = 0,
  setOpenChatWindow = () => {},
  openChatWindow = false,
  isParent = false,
  width = 0,
}) => {
  const chatStore = useChatStore();
  const teamStore = useMyTeamStore();
  const { selectedTeam, teams } = useTeamStore(
    useShallow((state) => ({
      selectedTeam: state.selectedTeam,
      teams: state.teams,
    }))
  );
  const profileStore = useProfileStore();
  const myTeamStore = useMyTeamStore();
  const nicheStore = useNicheStore();
  const navigate = useNavigate();
  const activityStore = useActivityStore();
  const honorStore = useHonorStore();
  const courseStore = useCourseStore();
  const testStore = useTestStore();
  const gpaStore = useGPAStore();
  const essayStore = useEssayStore();
  const referralStore = useInviteAFriendStore();
  const [chatDetails, setChatDetails] = useState({});
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const studentStore = useStudentStore();
  const adminStore = useAdminStore();
  const [receipientDetails, setReceipientDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useThemeStore();
  const { user } = useAuth();
  const googleDocs = useGoogleDocs();
  const applicationStore = useApplicationStore();
  const myStoryStore = useMyStoryStore(
    useShallow((state) => ({
      storySlideOut: state.storySlideOut,
    }))
  );
  const mailingAddress =
    profileStore?.profile?.address?.city +
    ',' +
    profileStore?.profile?.address?.state +
    ',' +
    profileStore?.profile?.address?.postal_code;
  const gpaData = gpaStore?.gpaScores?.map((gpa) => {
    return {
      GPAData: gpa.GPAData,
      RankData: gpa.RankData,
    };
  });

  function enrichNicheData(data) {
    // Create a map for quick lookup of niches by nicheId
    const nichesMap = data.niches.reduce((acc, niche) => {
      acc[niche.id] = niche;
      return acc;
    }, {});

    // Helper function to replace nicheId with nicheData
    const replaceNicheIdWithNicheData = (item) => {
      if (item.nicheId && nichesMap[item.nicheId]) {
        item.nicheName = nichesMap[item.nicheId].nicheName;
        delete item.nicheId; // Remove the nicheId property if you want to
      }
    };

    // Replace nicheId with nicheData in activities
    data.activities.forEach(replaceNicheIdWithNicheData);

    // Replace nicheId with nicheData in honors
    data.honors.forEach(replaceNicheIdWithNicheData);
    data.courses.forEach(replaceNicheIdWithNicheData);

    data.niches = data.niches?.map((niche) => ({
      nicheName: niche.nicheName,
    }));

    return data;
  }

  // Get the maximum SAT and ACT scores
  const ACTScores = testStore?.testScores?.filter(
    (score) =>
      testStore.testTypes.find((test) => test.type === 'ACT')?.id ===
      score.testType
  );
  const SATScores = testStore?.testScores?.filter(
    (score) =>
      testStore?.testTypes?.find((test) => test.type === 'SAT')?.id ===
      score.testType
  );
  const compositeScores =
    ACTScores?.flatMap((entry) =>
      entry.scores?.filter((score) => score.subjectName === 'Composite')
    ) ?? [];
  const TotalScores =
    SATScores?.flatMap((entry) =>
      entry?.scores?.filter((score) => score?.subjectName === 'Total')
    ) ?? [];

  const maxACTScore =
    Math.max(...compositeScores?.map((score) => score?.scoreValue)) ?? 0;

  const maxSATScore =
    Math.max(...TotalScores?.map((score) => score?.scoreValue)) ?? 0;

  const enrichedData = enrichNicheData({
    activities: activityStore?.activities?.map((activity) => ({
      nicheId: activity.nicheId,
      activityName: activity.activityName,
      weeksPerYear: activity.weeksPerYear,
      hoursPerWeek: activity.hoursPerWeek,
      activityType: activity.activityType,
      docInformation: activity?.docInformation?.map((doc) => ({
        content: doc.content,
      })),
      schoolYears: activity.grades,
    })),
    honors: honorStore?.honors?.map((honor) => ({
      honorName: honor.honorName,
      schoolYears: honor.grades,
      docInformation: honor.docInformation?.map((doc) => ({
        content: doc.content,
      })),
      honorTypes: honor.honorTypes?.map((honor) => ({
        label: honor.label,
      })),
      nicheId: honor.nicheId,
    })),
    niches: nicheStore.niches,
    courses: courseStore?.courses?.map((course) => ({
      courseName: course.courseName,
      courseDescription: course.description,
      schoolYears: course.grades,
      nicheId: course.nicheId,
    })),
    colleges: applicationStore?.applications?.map((application) => ({
      collegeName: application.label,
      applicationStatus: collegeApplicationStatusList?.find(
        (item) =>
          item.value ===
          (application?.applicationStatus
            ? application?.applicationStatus
            : 'not-applying')
      )?.label,
    })),
    testScores: {
      SAT: maxSATScore,
      ACT: maxACTScore,
    },
    gpaData: gpaData,
  });

  useEffect(() => {
    if (
      (chatStore.openChatType === 'topic' ||
        chatStore.openChatType === 'archivedTopic') &&
      chatStore.openChatId &&
      !chatStore.isChatOpen
    ) {
      setIsLoading(true);
      const chatRoomDetails = chatStore.chatRoomsTopics?.find(
        (chatRoom) => chatRoom?.id === chatStore.openChatId
      );
      setChatDetails(chatRoomDetails);
      if (chatRoomDetails?.chatParticipants.length > 1) {
        const receipientId = chatRoomDetails?.chatParticipants?.find(
          (id) => id !== profileStore.profile?.uid
        );
        setReceipientInformation(receipientId);
      } else if (chatRoomDetails?.chatParticipants.length === 1) {
        setReceipientInformation({});
      }
    } else if (
      (chatStore.openChatType === 'directMessage' ||
        chatStore.openChatType === 'archivedMessage') &&
      chatStore.openChatId &&
      !chatStore.isChatOpen
    ) {
      const list = chatStore.chatRoomsDM?.find(
        (chatRoom) => chatRoom?.id === chatStore.openChatId
      );
      setChatDetails(list);
      const receipientId = list?.chatParticipants?.find(
        (id) => id !== profileStore.profile?.uid
      );
      setReceipientInformation(receipientId);
    }
    //eslint-disable-next-line
  }, [
    chatStore.openChatId,
    chatStore.chatRoomsTopics.length,
    chatStore.chatRoomsDM,
  ]);

  const setReceipientInformation = async (receipientId) => {
    const profileDataForReceipient = await getProfileData(receipientId);
    setReceipientDetails({
      id: profileDataForReceipient?.id || '',
      uid: profileDataForReceipient?.uid || '',
      firstName: profileDataForReceipient?.firstName || '',
      lastName: profileDataForReceipient?.lastName || '',
      email: profileDataForReceipient?.email || '',
      photoURL: profileDataForReceipient?.photoURL || '',
      receiveNotifications:
        profileDataForReceipient?.receiveNotifications || false,
    });
    if (receipientId) {
      chatStore.setIsChatOpen(true);
      setIsLoading(false);
    }
  };

  const onAddTopic = () => {
    chatStore.setIsAddingTopic(true);
  };

  const onAddTeamMember = () => {
    teamStore.setIsAddingTeamMember(true);
  };

  const updateChatRoomCollectionForMessage = async (id) => {
    await updateStoryItem(
      {
        id: id,
        messageUpdatedAt: serverTimestamp(),
      },
      addStoryItemTypes.CHAT_ROOM
    );
  };

  const getPromptBasedContext = async (type, contextData) => {
    switch (type) {
      case 'general':
        return '';

      case 'myHub':
        return contextData
          .replace('firstName', profileStore.profile.firstName)
          .replace('profileType', profileStore.profile.profileType)
          .replace('studentGrade', `${profileStore.profile.grade}th`);

      case 'niche':
        return contextData
          .replace('ACTIVITIES', getActivityContext(activityStore.activities))
          .replace('HONORS', getHonorContext(honorStore.honors))
          .replace('COURSES', getCourseContext(courseStore.courses))
          .replace('NICHES', getNicheContext(nicheStore.niches));

      case 'activity':
        return contextData
          .replace('NICHES', getNicheContext(nicheStore.niches))
          .replace('MAILING_ADDRESS', mailingAddress || 'not_entered');
      case 'honor':
      case 'course':
      case 'essay':
        return contextData
          .replace('NICHES', getNicheContext(nicheStore.niches))
          .replace('ACTIVITIES', getActivityContext(activityStore.activities))
          .replace('HONORS', getHonorContext(honorStore.honors))
          .replace('COURSES', getCourseContext(courseStore.courses))
          .replace('ESSAYS', getEssayContext(essayStore.essays));

      case 'essayDetail':
        const selectedEssay = essayStore.essays?.find(
          ({ id }) => id === essayStore?.currentSelectedEssay?.id
        );

        const googleDocData = await googleDocs.getGoogleDoc(
          selectedEssay?.docs?.split('/')[5],
          isParent
        );

        const selectedTemplate = adminStore.applicationRequirements?.find(
          ({ essays }) =>
            essays && essays.length > 0
              ? essays.some(({ essayId, id }) =>
                  [id, essayId].includes(selectedEssay?.essayReferenceId)
                )
              : false
        );

        const selectedEssayFromTemplate = selectedTemplate?.essays?.find(
          ({ id, essayId }) =>
            [id, essayId].includes(selectedEssay?.essayReferenceId)
        );

        const isCoreEssay = selectedEssay?.type === 'coreEssay';

        if (config.environment === 'v2Development') {
          console.log('V20231106 - Selected Essay : ', selectedEssay);
          console.log(
            'V20231107 - Selected essayReferenceId : ',
            selectedEssay?.essayReferenceId
          );
          console.log('V20231106 - Selected Template : ', selectedTemplate);
          console.log(
            'V20231106 - Selected Essay from above Template : ',
            selectedEssayFromTemplate
          );
          console.log('V20231106 - Is core essay? : ', isCoreEssay);
          console.log(
            'V20231106 - Essay Name : ',
            selectedEssay?.essayName || 'none'
          );
          console.log(
            'V20231106 - Essay Content : ',
            googleDocData?.content ||
              selectedEssay?.docInformation?.content ||
              'none'
          );
          console.log(
            'V20231106 - Essay Word Limit : ',
            selectedEssayFromTemplate?.wordLimit || 'none'
          );
          console.log(
            'V20231106 - Essay Word Limit : ',
            selectedEssayFromTemplate?.prompt || 'none'
          );
        }

        return contextData
          .replace(
            'NICHES_WITH_STORY_ITEMS',
            getNicheStoryItemContext(
              nicheStore.niches,
              activityStore.activities,
              honorStore.honors,
              courseStore.courses
            )
          )
          .replace('essayName', selectedEssay?.essayName || 'none')
          .replace(
            'essayContent',
            googleDocData?.content ||
              selectedEssay?.docInformation?.content ||
              'none'
          )
          .replace(
            'essayWordLimit',
            isCoreEssay
              ? 'none'
              : selectedEssayFromTemplate?.wordLimit || 'none'
          )
          .replace(
            'essayPrompt',
            isCoreEssay ? 'none' : selectedEssayFromTemplate?.prompt || 'none'
          );

      case 'myStory':
        return contextData
          .replace('NICHES', getNicheContext(nicheStore.niches))
          .replace('ACTIVITIES', getActivityContext(activityStore.activities))
          .replace('HONORS', getHonorContext(honorStore.honors))
          .replace('COURSES', getCourseContext(courseStore.courses))
          .replace('MAILING_ADDRESS', mailingAddress || 'not_entered')
          .replace(
            'SAT_SCORE',
            getSatTestContext(
              testStore?.testTypes,
              testStore?.testScores,
              testStore?.testSubjectsSAT
            )
          )
          .replace(
            'ACT_SCORE',
            getActTestContext(
              testStore?.testTypes,
              testStore?.testScores,
              testStore?.testSubjectsACT
            )
          );

      case 'application':
        return contextData
          .replace('NICHES', getNicheContext(nicheStore.niches))
          .replace('ACTIVITIES', getActivityContext(activityStore.activities))
          .replace('HONORS', getHonorContext(honorStore.honors))
          .replace('COURSES', getCourseContext(courseStore.courses))
          .replace('MAILING_ADDRESS', mailingAddress || 'not_entered')
          .replace(
            'SAT_SCORE',
            getSatTestContext(
              testStore?.testTypes,
              testStore?.testScores,
              testStore?.testSubjectsSAT
            )
          )
          .replace(
            'ACT_SCORE',
            getActTestContext(
              testStore?.testTypes,
              testStore?.testScores,
              testStore?.testSubjectsACT
            )
          );

      case 'applicationDetail':
        return contextData
          .replace('NICHES', getNicheContext(nicheStore.niches))
          .replace('ACTIVITIES', getActivityContext(activityStore.activities))
          .replace('HONORS', getHonorContext(honorStore.honors))
          .replace('COURSES', getCourseContext(courseStore.courses))
          .replace('MAILING_ADDRESS', mailingAddress || 'not_entered')
          .replace(
            'COLLEGE_NAME',
            getCollegeName(
              applicationStore?.isCollegeId,
              applicationStore?.applications
            )
          )
          .replace(
            'SAT_SCORE',
            getSatTestContext(
              testStore?.testTypes,
              testStore?.testScores,
              testStore?.testSubjectsSAT
            )
          )
          .replace(
            'ACT_SCORE',
            getActTestContext(
              testStore?.testTypes,
              testStore?.testScores,
              testStore?.testSubjectsACT
            )
          );

      default:
        return '';
    }
  };

  const onSendMessage = async (
    messageText,
    callback = () => {},
    messages = [],
    storyContext = '',
    templateId = ''
  ) => {
    try {
      callback();

      //Adding message to chat room collection
      const chatMessageDocumentId = await addStoryItem(
        {
          messageContent: messageText,
          messageType: 'text',
          timeStamp: Date.now(),
          senderId: profileStore.profile?.uid,
          isRead: false,
        },
        `${addStoryItemTypes.CHAT_ROOM}/${chatStore.openChatId}/${addStoryItemTypes.CHAT_MESSAGES}`
      );

      if (chatMessageDocumentId) {
        //Checking if the recipient is UNI mail
        if (receipientDetails?.email.includes(ASK_UNI_MAIL)) {
          chatStore.setIsAiTyping(true);
          const GENERAL_TEMPLATE_ID = 'AI-Prompt-General-Introduction-Public';

          //This fetches the general instruction and returns an array
          const generalInstructions = await getStoryItem(UNIVERSILY_TEMPLATES, [
            {
              property: 'templateType',
              operator: firebaseQueryOperators.EQUAL_TO,
              value: GENERAL_TEMPLATE_ID,
            },
          ]);

          //Storing general instructions text
          const generalInstructionsText =
            generalInstructions.at(0)?.templateText;
          //Fetching the prompt template from the database
          const universilyTemplates = await getStoryItem(UNIVERSILY_TEMPLATES, [
            {
              property: 'templateType',
              operator: firebaseQueryOperators.EQUAL_TO,
              value: templateId,
            },
          ]);
          if (universilyTemplates && universilyTemplates?.length > 0) {
            const complexPromptInstruction =
              universilyTemplates.at(0)?.templateText; //context
            const userContext = getUserContext(
              user,
              enrichedData,
              isParent,
              selectedTeam
            ); //general user sentence ex. hello I am {name}...

            // Extra information for prompt, can be essay content, essay name, college details etc.
            const updatedStoryContext = await getPromptBasedContext(
              chatStore.promptStoryType,
              storyContext
            );

            // Selected parameters for the AI API
            const selectedParameter = {
              candidate_count: universilyTemplates?.at(0)?.parameter
                ?.candidate_count
                ? +universilyTemplates?.at(0)?.parameter?.candidate_count
                : 1,
              max_output_tokens: universilyTemplates?.at(0)?.parameter
                ?.max_output_tokens
                ? +universilyTemplates?.at(0)?.parameter?.max_output_tokens
                : 256,
              temperature: universilyTemplates?.at(0)?.parameter?.temperature
                ? +universilyTemplates?.at(0)?.parameter?.temperature
                : 0.2,
              top_p: universilyTemplates?.at(0)?.parameter?.top_p
                ? +universilyTemplates?.at(0)?.parameter?.top_p
                : 0.8,
              top_k: universilyTemplates?.at(0)?.parameter?.top_k
                ? +universilyTemplates?.at(0)?.parameter?.top_k
                : 40,
            };

            // Selected parameter for the Gemini AI
            const selectedParameterGemini = {
              candidate_count: universilyTemplates?.at(0)?.parameter
                ?.candidate_count
                ? +universilyTemplates?.at(0)?.parameter?.candidate_count
                : 1,
              max_output_tokens: universilyTemplates?.at(0)?.parameter
                ?.max_output_tokens
                ? +universilyTemplates?.at(0)?.parameter?.max_output_tokens
                : 256,
              temperature: universilyTemplates?.at(0)?.parameter?.temperature
                ? +universilyTemplates?.at(0)?.parameter?.temperature
                : 0.2,
              top_p: universilyTemplates?.at(0)?.parameter?.top_p
                ? +universilyTemplates?.at(0)?.parameter?.top_p
                : 0.8,
            }

            let chatHistory = [];

            //To remove the welcome message from the chat history
            const filteredMessages = messages.filter(
              (message) =>
                !message.messageContent.includes(
                  welcomeMessageFromAI.replace('Student', user?.firstName)
                )
            );
            if (filteredMessages && filteredMessages.length > 0) {
              sortArrayByRecentDate(filteredMessages).forEach((message) => {
                if (
                  message?.senderId === profileStore.profile?.uid &&
                  message?.messageType === 'text'
                ) {
                  chatHistory.push({
                    author: 'user',
                    content:
                      message?.messageContentLLM || message?.messageContent,
                  });
                } else {
                  chatHistory.push({
                    author: 'bot',
                    content:
                      message?.messageContentLLM || message?.messageContent,
                  });
                }
              });
            }
            const hasExtraPromptInformation =
              PROMPTS_WITH_EXTRA_INFORMATION.hasOwnProperty(templateId);
            //Message content sent to the AI

            // Template for when chat type is  TOPIC
            let topicMessageContentTemplate =
              'This question is related to TOPIC. Here is data related to it: DATA';
            let topicMessageContentLLM = '';
            const isOpenChatTopic = chatStore.openChatType === 'topic';

            // If the chat type is TOPIC, then we need to add the topic data to the message
            if (isOpenChatTopic) {
              if (
                chatDetails?.topicType === 'Niche' &&
                chatDetails?.utilityId
              ) {
                const nicheData = nicheStore.nichesById[chatDetails.utilityId];
                const formattedNicheData = {
                  nicheName: nicheData?.nicheName,
                };

                topicMessageContentLLM = topicMessageContentTemplate
                  .replace('TOPIC', chatDetails?.topicType)
                  .replace('DATA', JSON.stringify(formattedNicheData));
              } else if (
                chatDetails?.topicType === 'Essays' &&
                chatDetails?.utilityId
              ) {
                const essayData =
                  essayStore?.essaysById[chatDetails?.utilityId];
                // Get the word limit and prompt for the essay

                const referenceEssayDetails =
                  essayStore?.commonEssays.find(
                    (essay) => essay?.id === essayData?.essayReferenceId
                  ) ||
                  applicationStore?.applications
                    ?.find(
                      (application) =>
                        application?.collegeId === essayData?.collegeId
                    )
                    .essays?.find(
                      (essay) =>
                        essay?.id === essayData?.essayReferenceId ||
                        essay?.essayId === essayData?.essayReferenceId
                    );

                const formattedEssayData = {
                  essayName: essayData?.essayName,
                  essayContent: essayData?.docInformation?.content,
                };
                if (referenceEssayDetails) {
                  formattedEssayData.wordLimit =
                    referenceEssayDetails?.wordLimit;
                  formattedEssayData.essayPrompt =
                    referenceEssayDetails?.essayPrompt ||
                    referenceEssayDetails?.prompt;
                }

                topicMessageContentLLM = topicMessageContentTemplate
                  .replace('TOPIC', chatDetails?.topicType)
                  .replace('DATA', JSON.stringify(formattedEssayData));
              } else if (
                chatDetails?.topicType === 'Activities' &&
                chatDetails.utilityId
              ) {
                const activityData =
                  activityStore.activitiesById[chatDetails.utilityId];

                const nicheDataRelatedToActivity =
                  nicheStore.nichesById[activityData?.nicheId];

                const formattedActivityData = {
                  activityName: activityData?.activityName,
                  weeksPerYear: activityData?.weeksPerYear,
                  hoursPerWeek: activityData?.hoursPerWeek,
                  activityType: activityData?.activityTypes,
                  description: activityData?.docInformation?.map(
                    (doc) => doc.content
                  ),
                  grades: activityData?.grades,
                  nicheName: nicheDataRelatedToActivity?.nicheName,
                };

                topicMessageContentLLM = topicMessageContentTemplate
                  .replace('TOPIC', chatDetails?.topicType)
                  .replace('DATA', JSON.stringify(formattedActivityData));
              } else if (
                chatDetails?.topicType === 'Honors' &&
                chatDetails.utilityId
              ) {
                const honorData = honorStore.honorsById[chatDetails.utilityId];

                const formattedHonorData = {
                  honorName: honorData?.honorName,
                  schoolYears: honorData?.grades,
                  description: honorData?.docInformation?.map(
                    (doc) => doc.content
                  ),
                  nicheName:
                    nicheStore.nichesById[honorData?.nicheId]?.nicheName,
                };

                topicMessageContentLLM = topicMessageContentTemplate
                  .replace('TOPIC', chatDetails?.topicType)
                  .replace('DATA', JSON.stringify(formattedHonorData));
              } else if (
                chatDetails?.topicType === 'Courses' &&
                chatDetails.utilityId
              ) {
                const courseData = courseStore.courses.find(
                  (course) => course.id === chatDetails.utilityId
                );

                const formattedCourseData = {
                  courseName: courseData?.courseName,
                  courseDescription: courseData?.description,
                  schoolYears: courseData?.grades,
                  nicheName:
                    nicheStore.nichesById[courseData?.nicheId]?.nicheName,
                };

                topicMessageContentLLM = topicMessageContentTemplate
                  .replace('TOPIC', chatDetails?.topicType)
                  .replace('DATA', JSON.stringify(formattedCourseData));
              } else if (
                chatDetails?.topicType === 'Tests' &&
                chatDetails.utilityId
              ) {
                const testType = testStore.testTypes.find(
                  (test) => test.id === chatDetails.utilityId
                );

                const testScores = testStore.testScores.filter(
                  (score) => score.testType === chatDetails.utilityId
                );

                const highestTotalScore = testScores.reduce((max, item) => {
                  const totalScore = item.scores.find(
                    (score) => score.subjectName === 'Total' || 'Composite'
                  ).scoreValue;
                  return totalScore > max ? totalScore : max;
                }, 0);

                const formattedTestData = {
                  testType: testType?.type,
                  testHighScore: highestTotalScore,
                };

                topicMessageContentLLM = topicMessageContentTemplate
                  .replace('TOPIC', chatDetails?.topicType)
                  .replace('DATA', JSON.stringify(formattedTestData));
              } else if (
                chatDetails?.topicType === 'Colleges' &&
                chatDetails.utilityId
              ) {
                const collegeData = applicationStore.applications.find(
                  (college) => college.id === chatDetails.utilityId
                );

                const formattedCollegeData = {
                  collegeName: collegeData?.label,
                  applicationStatus: collegeApplicationStatusList?.find(
                    (item) =>
                      item.value ===
                      (collegeData?.applicationStatus
                        ? collegeData?.applicationStatus
                        : 'not-applying')
                  )?.label,
                };

                topicMessageContentLLM = topicMessageContentTemplate
                  .replace('TOPIC', chatDetails?.topicType)
                  .replace('DATA', JSON.stringify(formattedCollegeData));
              }
            }

            const messageContentLLM = storyContext
              ? `${complexPromptInstruction} ${
                  hasExtraPromptInformation ? updatedStoryContext : ''
                }`
              : `${messageText} ${
                  isOpenChatTopic ? topicMessageContentLLM : ''
                }`;

            // Adding the user message to chatHistory,
            if (storyContext) {
              // if want to send only prompt without history then add user data and prompt in chatHistory here
              chatHistory = [
                {
                  author: 'user',
                  content: messageContentLLM,
                },
              ];
            } else if (chatHistory?.length > 0) {
              if (chatHistory.length % 2 === 0) {
                chatHistory = [
                  ...chatHistory,
                  {
                    author: 'user',
                    content: messageContentLLM,
                  },
                ];
              } else {
                // chatHistory remains unchanged
              }
            } else {
              chatHistory[0] = {
                author: 'user',
                content: messageContentLLM,
              };
            }

            // Calling the PALM AI API
            // const aiRes = await axiosPost(ApiRoutes.VERTEX_AI, {
            //   prompt: {
            //     context: `${generalInstructionsText}`,
            //     messages: chatHistory,
            //   },
            //   userData: userContext,
            //   parameter: {
            //     ...selectedParameter,
            //   },
            // });

            // Calling the Gemini AI API
            const aiRes = await axiosPost(ApiRoutes.GEMINI_AI, {
              prompt: {
                context: `${generalInstructionsText}`,
                messages: chatHistory,
              },
              userData: userContext,
              parameter: selectedParameterGemini,
            });
            if (aiRes?.data?.status) {
              await addStoryItem(
                {
                  messageContent: aiRes?.data?.content,
                  messageType: 'text',
                  timeStamp: Date.now(),
                  senderId: receipientDetails?.uid,
                  isRead: false,
                },
                `${addStoryItemTypes.CHAT_ROOM}/${chatStore.openChatId}/${addStoryItemTypes.CHAT_MESSAGES}`
              );
              await updateStoryItem(
                {
                  id: chatMessageDocumentId,
                  messageContentLLM,
                },
                `${addStoryItemTypes.CHAT_ROOM}/${chatStore.openChatId}/${addStoryItemTypes.CHAT_MESSAGES}`
              );
              chatStore.setIsAiTyping(false);
              chatStore.setIsPromptOpen(false);
            } else {
              chatStore.setIsAiTyping(false);
            }
          } else {
            console.log('v20231027-Prompt Template is missing');
          }
        }

        await updateChatRoomCollectionForMessage(chatStore.openChatId);
        if (
          receipientDetails?.receiveNotifications &&
          receipientDetails?.email
        ) {
          const params = {
            firstName: profileStore.profile?.firstName || '',
            lastName: profileStore.profile?.lastName || '',
            targetEmail: receipientDetails?.email,
            templateName: 'universily_chat_alert',
            timeStamp: format(new Date(), 'MMM do hh:mm aaa'),
            inviteLink: config.BASE_URL,
            content: messageText || '',
          };
        } else {
          console.log(
            `v20230810IST allow notifications is false for ${receipientDetails?.email}`
          );
        }
      }
    } catch (error) {
      console.log('error', error, error ? JSON.stringify(error) : '');
    }
  };

  const createDMChannel = async (reviewerId, unreadCount) => {
    // eslint-disable-next-line no-unused-vars
    const profileId = profileStore.profile?.uid;
    const existingChannel = chatStore.chatRoomsDM?.find((chatRoom) =>
      chatRoom?.chatParticipants?.includes(reviewerId)
    );
    if (existingChannel) {
      setIsLoading(true);
      chatStore.setOpenChatType('directMessage');
      chatStore.setOpenChatId(existingChannel?.id);
      if (unreadCount && unreadCount > 0) {
        await markMessagesAsRead(existingChannel?.id, unreadCount);
        await updateChatRoomCollectionForMessage(existingChannel?.id);
      }
    } else {
      setIsLoading(true);
      const response = await addStoryItem(
        {
          isTopic: false,
          archivedBy: [],
          chatParticipants: [reviewerId, profileStore.profile?.uid],
        },
        addStoryItemTypes.CHAT_ROOM
      );
      if (response) {
        chatStore.setOpenChatType('directMessage');
        chatStore.setOpenChatId(response);
      }
    }
  };

  const onEditTopic = () => {
    chatStore.setIsEditingTopic(true);
  };

  const onArchiveTopicMessage = async (id, profileId, archivedBy, itemType) => {
    chatStore.setOpenChatType(null);
    chatStore.setIsChatOpen(false);
    chatStore.setOpenChatId(null);
    let newArchivedBy = [];
    if (archivedBy.includes(profileId)) {
      setSnackbarMessage(`The ${itemType} has been unarchived`);
      newArchivedBy = archivedBy.filter((id) => id !== profileId);
    } else {
      setSnackbarMessage(`The ${itemType} has been archived`);
      newArchivedBy = [...archivedBy, profileId];
    }
    await updateStoryItem(
      {
        id: id,
        archivedBy: newArchivedBy,
      },
      addStoryItemTypes.CHAT_ROOM
    );
    //await updateChatRoomCollectionForMessage(id);
    setIsSnackbarOpen(true);
  };

  const markMessagesAsRead = async (id, unreadCount) => {
    const profileId = profileStore.profile?.uid;
    if (unreadCount && unreadCount > 0) {
      const chatMessages = await getMessagesForChatRoom(id, profileId, false);

      const response = await Promise.all(
        chatMessages?.map(async (chat) => {
          await updateStoryItem(
            {
              id: chat?.id,
              isRead: true,
            },
            `${CHAT_ROOMS}/${id}/${CHAT_MESSAGES}`
          );
        })
      );
      if (response) updateChatRoomCollectionForMessage(id);
    }
  };

  const markMessagesAsUnread = async (id) => {
    const profileId = profileStore.profile?.uid;
    const unreadReceivedChats = await getMessagesForChatRoom(
      id,
      profileId,
      true
    );
    if (unreadReceivedChats?.length > 0 && unreadReceivedChats[0]) {
      await updateStoryItem(
        {
          id: unreadReceivedChats[0]?.id,
          isRead: false,
        },
        `${CHAT_ROOMS}/${id}/${CHAT_MESSAGES}`
      );
      await updateChatRoomCollectionForMessage(id);
    } else {
      setSnackbarMessage(
        'This conversation cannot be marked as unread as there are no messages.'
      );
      setIsSnackbarOpen(true);
    }
  };

  const onInviteAStudent = () => {
    referralStore.setIsInvitingAFriend(true);
  };

  const onOpenUtility = () => {
    if (chatDetails.isTopic) {
      if (
        isParent &&
        studentStore.selectedStudent?.uid !== receipientDetails.uid
      ) {
        studentStore?.setSelectedStudent(
          studentStore?.student
            .flat()
            .find((student) => student.uid === receipientDetails?.uid)
        );
      }
      if (chatDetails?.topicType === 'Activities') {
        if (activityStore.activitiesById[chatDetails.utilityId]) {
          navigate('/myStory/activities');
          activityStore.setAddingOrEditingDocs(true);
          activityStore.setAddEditActivityId(chatDetails.utilityId);
        }
      } else if (chatDetails?.topicType === 'Honors') {
        if (honorStore.honorsById[chatDetails?.utilityId]) {
          navigate('/myStory/honors');
          honorStore.setAddingOrEditingHonorDocs(true);
          honorStore.setAddEditHonorId(chatDetails.utilityId);
        }
      } else if (chatDetails?.topicType === 'Niche') {
        if (nicheStore.nichesById[chatDetails.utilityId]) {
          navigate('/myStory/niche');
          nicheStore.setViewNicheId(chatDetails.utilityId);
          nicheStore.setIsViewDetails(true);
        }
      } else if (chatDetails?.topicType === 'Courses') {
        navigate('/myStory/courses');
      } else if (chatDetails?.topicType === 'My Story') {
        navigate('/myStory');
      } else if (chatDetails?.topicType === 'Tests') {
        navigate('/myStory/tests');
      } else if (chatDetails?.topicType === 'Essays') {
        navigate('/myEssay');
        essayStore.setAddOrEditEssayDocsId(chatDetails.utilityId);
        essayStore.setAddOrEditEssayDocs(true);
      } else if (chatDetails?.topicType === 'Colleges') {
        navigate('/myColleges');
        applicationStore.setIsAddApplicationRequirement(true);
        applicationStore.setIsCollegeId(
          applicationStore.applicationsById[chatDetails.utilityId].collegeId
        );
      }
    }
  };

  return (
    //<ClickAwayListener
    //  mouseEvent="onMouseDown"
    //  touchEvent="onTouchStart"
    //  onClickAway={() => {
    //    if (openChatWindow) {
    //      setOpenChatWindow(false);
    //    }
    //  }}
    //>
    <Drawer
      variant="persistent"
      anchor="right"
      open={openChatWindow}
      BackdropProps={{ invisible: true }}
      sx={{
        zIndex: 90,
        backgroundColor: '#FCFCFD',
        position: 'relative',
        marginLeft: 'auto',
        border: '1px solid #616675',
        '& .MuiBackdrop-root': {
          display: 'none',
        },
        '& .MuiDrawer-paper': {
          width: width,
          position: !isMobile ? 'absolute' : 'default',
          height: height,
        },
      }}
      onClose={() => {
        setOpenChatWindow(false);
      }}
    >
      <SuccessSnackbar
        message={snackbarMessage}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => {
          setIsSnackbarOpen(false);
          setSnackbarMessage('');
        }}
      />
      {!chatStore.isChatOpen && !isLoading && (
        <ChatList
          teamMembers={myTeamStore.myTeam?.filter(
            (teamMember) => teamMember.invite_status === INVITE_STATUS.JOINED
          )}
          isParent={isParent}
          userId={profileStore.profile?.uid}
          onAddTopic={onAddTopic}
          onAddTeamMember={onAddTeamMember}
          chatTopics={chatStore.chatRoomsTopics?.filter(
            (chatRoomTopic) =>
              !chatRoomTopic.archivedBy?.includes(profileStore.profile?.uid) &&
              chatRoomTopic.teamMemberExists &&
              (!chatStore.isFilterTopic ||
                chatRoomTopic.utilityId === chatStore.utilityId)
          )}
          chatDMs={chatStore.chatRoomsDM?.filter(
            (chatRoomDM) =>
              !chatRoomDM.archivedBy?.includes(profileStore.profile?.uid)
          )}
          archivedChatTopics={chatStore.chatRoomsTopics?.filter(
            (chatRoomTopic) =>
              chatRoomTopic.archivedBy?.includes(profileStore.profile?.uid) ||
              !chatRoomTopic?.teamMemberExists
          )}
          archivedChatDMs={chatStore.chatRoomsDM?.filter((chatRoomDM) =>
            chatRoomDM.archivedBy?.includes(profileStore.profile?.uid)
          )}
          onChatClick={(id, type, unreadCount) => {
            chatStore.setOpenChatType(type);
            //chatStore.setIsChatOpen(true);
            chatStore.setOpenChatId(id);
            markMessagesAsRead(id, unreadCount);
          }}
          markMessagesAsRead={markMessagesAsRead}
          markMessagesAsUnread={markMessagesAsUnread}
          onArchiveTopicMessage={onArchiveTopicMessage}
          createDMChannel={(reviewerId, unreadCount) =>
            createDMChannel(reviewerId, unreadCount)
          }
          onInviteAStudent={onInviteAStudent}
        />
      )}
      {chatStore.isChatOpen && !isLoading && (
        <ChatBody
          teamMembers={myTeamStore.myTeam}
          createdAt={
            chatDetails?.createdAt ? chatDetails.createdAt.toDate() : ''
          }
          onEditTopic={onEditTopic}
          userId={profileStore.profile?.uid}
          onSendMessage={(
            messageText,
            callback,
            messages,
            storyContext = '',
            id = ''
          ) => onSendMessage(messageText, callback, messages, storyContext, id)}
          openChatId={chatStore.openChatId}
          topicName={
            chatDetails?.isTopic
              ? chatDetails?.topicName
              : `${chatDetails?.firstName || ''} ${chatDetails?.lastName || ''}`
          }
          teamMemberExists={chatDetails?.teamMemberExists}
          setReceipientDetails={setReceipientDetails}
          isTopic={chatDetails?.isTopic}
          openChatType={chatStore.openChatType}
          receipientDetails={receipientDetails}
          utility={chatDetails?.topicType}
          utilityId={chatDetails?.utilityId}
          archivedBy={chatDetails?.archivedBy}
          markMessagesAsRead={markMessagesAsRead}
          onOpenUtility={onOpenUtility}
          onChatBack={() => {
            if (
              isMobile &&
              chatStore.isGenAIChatOpen &&
              myStoryStore.storySlideOut
            ) {
              chatStore.setIsGenAIChatOpen(false);
              chatStore.setIsChatWindowOpen(false);
              return;
            }
            chatStore.setIsChatOpen(false);
            chatStore.setOpenChatId(null);
            chatStore.setIsGenAIChatOpen(false);
            setReceipientDetails({});
          }}
          onArchiveTopicMessage={() =>
            onArchiveTopicMessage(
              chatDetails?.id,
              profileStore.profile?.uid,
              chatDetails?.archivedBy,
              chatDetails.isTopic ? 'Topic' : 'Message'
            )
          }
        />
      )}
      {isLoading && (
        <div className=" w-full h-screen flex items-center justify-center">
          <CircularProgress />
        </div>
      )}
    </Drawer>
    //</ClickAwayListener>
  );
};

export default ChatWindow;
